import React from 'react'

import logo from '../assets/images/logo-memz.png'

const Header = props => (
  <header id="header" className="alt">
    <span className="logo">
      <img src={logo} alt="Memz One Logo" />
    </span>
    <h1>Memz One</h1>
    <h2>Move problems off your mind into Memz One</h2>
  </header>
)

export default Header
