import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Waypoint from 'react-waypoint'

import Layout from '../components/layout'
import Header from '../components/Header'
import Nav from '../components/Nav'
import screenshot from '../assets/images/screenshot_01.jpg'
import { createDetailsLink } from '../utils';

class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stickyNav: false,
    }
  }

  _handleWaypointEnter = () => {
    this.setState(() => ({ stickyNav: false }))
  }

  _handleWaypointLeave = () => {
    this.setState(() => ({ stickyNav: true }))
  }

  render() {
    return (
      <Layout>
        <Helmet title="Memz One - Hierarchical Notepad" />

        <Header />

        <Waypoint
          onEnter={this._handleWaypointEnter}
          onLeave={this._handleWaypointLeave}
        />
        <Nav sticky={this.state.stickyNav} />

        <div id="main">
          <section id="intro" className="main">
            <div className="spotlight">
              <div className="content">
                <header className="major">
                  <h2>Our promise</h2>
                </header>
                <p>
                  We cannot solve all problems for you, but here is how we can
                  help
                </p>
                <ul>
                  <li>Reduce stress by writing your problems down</li>
                  <li>Turn them into actionable tasks</li>
                  <li>Keep track of your progress</li>
                  <li>All your notes always at your fingertips</li>
                  <li>Boost your productivity</li>
                </ul>
                <ul className="actions">
                  <li>
                    <Link to={createDetailsLink()} className="button">
                      Learn More
                    </Link>
                  </li>
                </ul>
              </div>
              <span className="image-raw">
                <img src={screenshot} alt="" />
              </span>
            </div>
          </section>

          <section id="first" className="main special">
            <header className="major">
              <h2>Great Features</h2>
            </header>
            <ul className="features">
              <li>
                <span className="icon major style1 fa-sitemap" />
                <h3>Hierarchy support</h3>
                <p>Organise your notes in unlimited hierarchy</p>
              </li>
              <li>
                <span className="icon major style3 fa-cloud" />
                <h3>Sync and access anywhere</h3>
                <p>
                  Use our fast and reliable Cloud Service to sync your notes
                  across your devices and access from the browser
                </p>
              </li>
              <li>
                <span className="icon major style5 fa-key" />
                <h3>Enhanced Security</h3>
                <p>
                  Additionally protect selected notes using AES256 encryption
                  algorithm.
                </p>
              </li>
            </ul>
            <footer className="major">
              <ul className="actions">
                <li>
                  <Link to={createDetailsLink()} className="button">
                    Learn More
                  </Link>
                </li>
              </ul>
            </footer>
          </section>

          <section id="second" className="main special">
            <header className="major">
              <h2>Happy Users</h2>
              <p>Don’t just take it from us, let our users do the talking!</p>
            </header>
            <ul className="features">
              <li>
                <span className="icon major style1 fa-user" />
                <h3>Franklin R Reith</h3>
                <p>
                  Memz – this product has much improved the effectiveness of my
                  work and quality of life – really!… Following various searches
                  and research I was recommended in 2013 to Memz and found it so
                  much better than other glitzy simplistic ‘to-do lists’ as Memz
                  is a very powerful project organizer based on multi-level tree
                  hierarchy notes, or if you want, it is excellent for
                  straightforward to do lists! The product was revolutionised in
                  mid 2014 by implementation in a cloud where I can
                  access/change via my PC, tablet, or phone. Absolutely
                  brilliant and now I have almost no paper to carry. Wonderful!
                </p>
              </li>
              <li>
                <span className="icon major style3 fa-user" />
                <h3>John Lewis</h3>
                <p>
                  I have been using Memz for several years now and it just keeps
                  getting better! I use Memz for literally all my information
                  management needs! From calendar to meeting notes, diary to
                  contacts, business management to prayerlist, Memz handles it
                  all and looks fantastic as well! Memz adapts to whatever your
                  note taking needs may be. I have over 3000 notes, each in its
                  proper place due to its unique hierarchy method. Is multi word
                  search and user friendly note placement makes it easy to find
                  whatever I need, all without compromising the apps
                  performance. Encrypted security allows me to know my sensitive
                  information is safe and secure! It just doesn’t get any better
                  than that!
                </p>
              </li>
              <li>
                <span className="icon major style5 fa-user" />
                <h3>Fergal Gordon</h3>
                <p>
                  Once I found Memability, now Memz, I knew I had the features I
                  wanted. It has a clear a hierarchical structure and easy to
                  navigate. Moving and copying notes is easy. Memz has a very
                  clean look and feel, the design emphasis appears to on
                  function rather than eye candy, making for a very business
                  like application. I use it as an index for my computer files,
                  hard copy files and e-mails. This makes Memz for me an
                  invaluable tool for finding information. The search tool is
                  straight forward and interactive. The final feature that
                  attracted me to Memz was the ability to use it offline, as I
                  don’t have a substantial data package and generally only sync
                  with my home WiFi.
                </p>
              </li>
            </ul>
            <footer className="major">
              <ul className="actions">
                <li>
                  <Link to={createDetailsLink()} className="button">
                    Learn More
                  </Link>
                </li>
              </ul>
            </footer>
          </section>

          <section id="cta" className="main special">
            <header className="major">
              <h2>See it for yourself</h2>
              <p>
                It's completely free, Ads free,
                <br />
                you don't even need to Register or Sign up.
                <br />
                Just start using it.
              </p>
            </header>
            <footer className="major">
              <ul className="actions">
                <li>
                  <Link to={createDetailsLink()} className="button special">
                    Get Started
                  </Link>
                </li>
                <li>
                  <Link to={createDetailsLink()} className="button">
                    Learn More
                  </Link>
                </li>
              </ul>
            </footer>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Index
