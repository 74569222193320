import React from 'react'
import Scrollspy from 'react-scrollspy'
import { Link } from 'gatsby'
import Scroll from './Scroll'
import { createDetailsLink } from '../utils';

const Nav = props => (
  <nav id="nav" className={props.sticky ? 'alt' : ''}>
    <Scrollspy
      items={['intro', 'first', 'second', 'cta']}
      currentClassName="is-active"
      offset={-300}
    >
      <li>
        <Scroll type="id" element="intro">
          <a href="#">Introduction</a>
        </Scroll>
      </li>
      <li>
        <Scroll type="id" element="first">
          <a href="#">Great Features</a>
        </Scroll>
      </li>
      <li>
        <Scroll type="id" element="second">
          <a href="#">Happy Users</a>
        </Scroll>
      </li>
      <li className="primary">
        <Link to={createDetailsLink()} className="button">
          Get Started
          </Link>
      </li>
    </Scrollspy>
  </nav>
)

export default Nav
