import querystring from 'querystring'

let query = typeof document !== `undefined` ? document.location.search : '';
if (query) {
    const params = querystring.parse(query.replace('?', ''));
    if (params) {
        if (!params.referrer) {
            if (params.utm_campaign) {
                const gaCampaignParamNames = ['utm_campaign', 'utm_medium', 'utm_term', 'utm_content', 'utm_source', 'anid'];
                const gaCampaignParams = [];
                gaCampaignParamNames.forEach(paramName => {
                    if (params[paramName]) {
                        gaCampaignParams.push(`${paramName}=${params[paramName]}`);
                    }
                });

                const referrer = encodeURIComponent(gaCampaignParams.join('&'));
                query = `${query}&referrer=${referrer}`;
            }
        }
    }
}


export const createDetailsLink = () => `/details${query}`